const defaultTheme = require('tailwindcss/defaultTheme')

const theme = {
  boxShadow: {
    default: '0 4px 24px rgba(0, 0, 0, 0.15)',
  },
  // TODO: Adjust the values for the carousel breakpoints
  carousel: {
    base: 1.4,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 4,
    '2xl': 5,
  },
  colors: {
    sg: {
      black: '#000000',
      white: '#FFFFFF',
      'dark-grey': '#8F8E8E',
      grey: '#CFCFCF',
      'light-grey': '#EFEFF1',
      'light-sand': '#E7E4DE',
      sand: '#CEC9C1',
      'neon-yellow': '#E4FF79',
      orange: '#D94C29',
      red: '#CF3030',
      rose: '#F5C2C2',
      green: '#78B96E',
      'light-green': '#EEF4ED',
      blue: '#6293CD',
      'pure-I': '#BCD0BD',
      'pure-II': '#3B654B',
      'nest-I': '#F4EDDD',
      'nest-II': '#B64A34',
      'pop-I': '#D7FFB7',
      'pop-II': '#7B2195',
      'finest-I': '#330D20',
      'finest-II': '#E2E3D8',
      'lytte-I': '#D9D9D9',
      'lytte-II': '#FFFFFF',
      yellow: '#F3D751',
    },
    transparent: 'transparent',
    current: 'currentColor',
    primary: {
      black: '#141414',
      white: '#ffffff',
      pink: '#E12A57',
      red: '#BB2323',
      green: '#3C654B',
    },
    secondary: {
      nude: '#D6AB97',
      sand: '#BB7859',
      caramel: '#DDA46F',
      love: '#371810',
      olive: '#A7A284',
      'light-olive': '#A7A284',
      taupe: '#D7C7BF',
      'medium-pink': '#E12A57',
      'dark-pink': '#BB2348',
      'extra-dark-grey': '#292929',
      'soft-black': '#141414',
      'facebook-blue': '#405A93',
      'google-green': '#34A853',
    },
    grey: {
      light: '#F4F4F4',
      medium: '#e5e5e5',
      'medium-2': '#cacaca33',
      'medium-3': '#CACACA',
      dark: '#909090',
      'dark-2': '#474747',
      border: '#c4c4c480',
    },
    facebook: '#3b5998',
    instagram: '#fb3958',
    youtube: '#FF0000',
    pinterest: '#c8232c',
  },
  fontSize: {
    p: ['14px', '22px'],
    'p-small': ['12px', '20px'],
    base: ['16px', '24px'],
    'h-desktop-hero': ['100px', '108px'],
    'h1-desktop': ['48px', '56px'],
    'h3-desktop': ['32px', '40px'],
    'h4-desktop': ['26px', '34px'],
    'h-mobile-hero': ['56px', '64px'],
    'h1-mobile': ['32px', '40px'],
    'h2-mobile': ['24px', '32px'],
    'h3-mobile': ['20px', '28px'],
    'h4-mobile': ['18px', '26px'],
    'link-txt-big': ['20px', '28px'],
    '80-90-sg': ['80px', '90px'],
    '40-45-sg': ['40px', '45px'],
    '70-80-sg': ['70px', '80px'],
    '30-35-sg': ['30px', '35px'],
    '60-70-sg': ['60px', '70px'],
    '20-25-sg': ['20px', '25px'],
    '50-60-sg': ['50px', '60px'],
    '15-20-sg': ['15px', '20px'],
    '15-22-sg': ['15px', '22px'],
    '15-15-sg': ['15px', '15px'],
    '12-20-sg': ['12px', '20px'],
    '12-12-sg': ['12px', '12px'],
    '12-14-sg': ['12px', '14px'],
    '10-16-sg': ['10px', '16px'],
    '10-12-sg': ['10px', '12px'],
  },

  debugScreens: {
    position: ['bottom', 'right'],
  },
  //If you make changes here, please adjust /lib/Media.tsx as well
  screens: {
    xs: '450px',
    sm: '600px',
    md: '768px',
    lg: '1024px',
    lgx: '1200px',
    xl: '1440px',
    '2xl': '1920px',
    '2k': '2560px',
    '4k': '3840px',
  },
  gridTemplateAreas: {
    footer: ['links newsletter logos', 'social social social', 'copyright copyright copyright'],
    'footer-mobile': ['links', 'newsletter', 'logos', 'social', 'copyright'],
    'filter-trigger-mobile': ['filters sort columns', 'total . .'],
    'filter-trigger': ['filters total columns sort'],
    'cart-item-mobile': ['name name', 'size size', 'price price', 'qty qty', 'remove total'],
    'cart-item': ['name . . .', 'size price qty total', 'remove . . .'],
  },
  fontFamily: {
    'right-grotesk': ['var(--font-right-grotesk)', ...defaultTheme.fontFamily.serif],
  },
  extend: {
    fontWeight: {
      normal: '500',
    },
    fontSize: {
      p: ['14px', '22px'],
      'p-normal': ['14px', '22px'],
      'p-small': ['12px', '20px'],
      'h-desktop-hero': ['100px', '108px'],
      'h1-desktop': ['48px', '56px'],
      'h2-desktop': ['32px', '40px'],
      'h3-desktop': ['26px', '34px'],
      'h4-desktop': ['20px', '28px'],
      'h-mobile-hero': ['56px', '64px'],
      'h1-mobile': ['32px', '40px'],
      'h2-mobile': ['24px', '32px'],
      'h3-mobile': ['20px', '28px'],
      'h4-mobile': ['18px', '26px'],
      'h1-desktop-large': [
        'clamp(3rem, 0.75rem + 2.5vw, 3.75rem)',
        'clamp(3.5rem, 0.875rem + 2.9167vw, 4.375rem)',
      ],
      'h2-desktop-large': [
        'clamp(2rem, 0.5rem + 1.6667vw, 2.5rem)',
        'clamp(2.5rem, 0.625rem + 2.0833vw, 3.125rem)',
      ],
      'h3-desktop-large': [
        'clamp(1.625rem, 0.4063rem + 1.3542vw, 2.03125rem)',
        'clamp(2.125rem, 0.5313rem + 1.7708vw, 2.65625rem)',
      ],
      'h4-desktop-large': [
        'clamp(1.25rem, 0.3125rem + 1.0417vw, 1.5625rem)',
        'clamp(1.75rem, 0.4375rem + 1.4583vw, 2.1875rem)',
      ],
    },
    gridTemplateColumns: {
      'auto-fit': 'repeat(auto-fit, minmax(0, 1fr))',
      'auto-fill': 'repeat(auto-fill, minmax(0, 1fr))',
      'auto-fill-45': 'repeat(auto-fill, 45px);',
      footer: ['1fr', 'auto', 'auto'],
    },
    gridTemplateRows: {
      'auto-fit': 'repeat(auto-fit, minmax(0, 1fr))',
      'auto-fill': 'repeat(auto-fill, minmax(0, 1fr))',
    },
    background: {
      transparent: 'transparent',
    },
    maxWidth: {
      'text-content': 'clamp(42.188rem, 161.2079rem + -132.2444vw, 2.5146875rem)',
    },
    minHeight: {
      'teaser-height': 'clamp(28.75rem, 21.8214rem + 10.8259vw, 34.8125rem)',
      'teaser-height-mobile': 'clamp(17.5rem, 8.6803rem + 31.3589vw, 28.75rem)',
    },
    spacing: {
      7.5: '1.875rem',
      8.5: '2.125rem',
    },
    lineHeight: {
      4.5: '1.125',
    },
  },
}

module.exports = { theme }
