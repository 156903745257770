import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useTranslation } from 'next-i18next'
import { LanguageButton } from '../../components/LanguageButton'
import { useState } from 'react'
import { BanderoleQuery } from '@contentfulTypes'
import { BanderoleNewsletter } from '~/modules/BanderoleNewsletter/BanderoleNewsletter'
import clsx from 'clsx'
import styles from '../../Header.module.scss'
import ArrowRight from '../../components/Icons/ArrowRight'
import Link from 'next/link'
import Copy from '../../components/Icons/Copy'
import useCountdownData from '~/hooks/useCountdownData'
import BanderoleCountdown from '../../components/BanderoleCountdown'

type Props = {
  banderoleData?: BanderoleQuery
}

export const DesktopBanderole = ({ banderoleData }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const locale = router.locale || DEFAULT_LOCALE
  const supportLocale = locale.split('-')?.[0]
  const [isPromoCodeCopied, setisPromoCodeCopied] = useState(false)
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)
  const countdownData = useCountdownData({
    isBanderoleVisible:
      banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.countDownDate &&
      !banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.hideCountDown,
    banderoleData: banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.countDownDate,
    rerenderCondition: banderoleData?.pageCollection?.items,
  })

  const copyToClipboard = () => {
    navigator?.clipboard?.writeText(
      banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.discountCode as string,
    )
    setisPromoCodeCopied(true)
  }

  const getSupportLink = (locale: string, supportLocale: string) => {
    if (locale === 'de-AT') return 'https://help.benuta.at/de/support/home'
    else if (locale === 'de-CH' || locale === 'de')
      return 'https://helpde.benuta.ch/de/support/home'
    else if (locale === 'fr-CH' || locale === 'fr')
      return 'https://helpfr.benuta.ch/fr/support/home'
    else if (locale === 'it-CH' || locale === 'it')
      return 'https://helpit.benuta.ch/it/support/home'
    else if (locale === 'en-GB') return 'https://help.benuta.co.uk/en/support/home'
    else
      return `https://help.benuta.com/${
        supportLocale === 'sv'
          ? 'sv-SE'
          : supportLocale === 'pt'
          ? 'pt-PT'
          : supportLocale === 'nn'
          ? 'nb-NO'
          : supportLocale
      }/support/home`
  }

  return (
    <>
      <div className="sticky top-0 z-50">
        <div
          className={clsx(
            'grid h-[55px] w-full max-w-[100vw] grid-cols-[1fr_3fr_1fr] overflow-hidden text-p print:hidden',
            styles.globalBanner,
          )}
          style={{
            background:
              (banderoleData?.pageCollection?.items[0]?.banderole?.backgroundColor as string) ??
              '#fff',
            color:
              (banderoleData?.pageCollection?.items[0]?.banderole?.textColor as string) ?? '#fff',
          }}
        >
          <div
            className={clsx(
              'flex w-full items-center justify-center !whitespace-nowrap',
              styles.bannerText,
            )}
          >
            {banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.newsletterText ? (
              <>
                <button
                  className="flex items-center justify-center font-extralight"
                  onClick={() => {
                    if (
                      !banderoleData?.pageCollection?.items[0]?.banderole?.countDown
                        ?.disableNewsletterPopup
                    )
                      setShowNewsletterPopup(true)
                  }}
                >
                  <span
                    className="text-p"
                    dangerouslySetInnerHTML={{
                      __html: banderoleData?.pageCollection?.items[0]?.banderole?.countDown
                        ?.newsletterText as string,
                    }}
                  />
                </button>
                {banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.promoText && (
                  <span
                    className="text-extralight ml-1 text-p"
                    dangerouslySetInnerHTML={{
                      __html: banderoleData?.pageCollection?.items[0]?.banderole?.countDown
                        ?.promoText as string,
                    }}
                  />
                )}
                {banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.fullClick && (
                  <>
                    <Link
                      href={banderoleData?.pageCollection?.items[0]?.banderole?.countDown.fullClick}
                      className="absolute inset-0"
                    />
                    <ArrowRight
                      className="ml-1 h-6 w-6"
                      stroke={
                        banderoleData?.pageCollection?.items[0]?.banderole?.textColor as string
                      }
                    />
                  </>
                )}
              </>
            ) : (
              <span className="mr-3 text-p">
                {t('Free Shipping', {
                  days: banderoleData?.pageCollection?.items[0]?.deliveryDays,
                })}
              </span>
            )}
            {banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.discountCode && (
              <div className="relative ml-[50px] font-extralight">
                <span className="mr-1 text-p">{t('CODE')}:</span>
                <button
                  onClick={() => copyToClipboard()}
                  disabled={isPromoCodeCopied}
                  className="text-center font-normal uppercase disabled:text-primary-white"
                >
                  <div className="flex items-center justify-center">
                    <span className="text-p">
                      {!isPromoCodeCopied
                        ? banderoleData?.pageCollection?.items[0]?.banderole?.countDown
                            ?.discountCode
                        : t('CODE COPYED')}
                    </span>
                    {!isPromoCodeCopied && <Copy className="ml-1 h-4 w-4" />}
                  </div>
                </button>
              </div>
            )}
            {countdownData.expired && (
              <span className="text-center text-p uppercase lgx:ml-[50px]">{t('EXPIRED')}</span>
            )}
            {countdownData.isReady && (
              <BanderoleCountdown
                countdownData={countdownData}
                isDesktop
                className="lgx:ml-[50px]"
              />
            )}
          </div>
          <div
            className={clsx(
              'banner-buttons relative flex flex-row items-center justify-end pr-20',
              styles.bannerButtons,
            )}
          >
            <div className="mr-8 font-extralight">
              <a href={getSupportLink(locale, supportLocale)} title={t('Help & Contact')}>
                {t('Help & Contact')}
              </a>
            </div>
            <LanguageButton />
          </div>
        </div>
      </div>
      <BanderoleNewsletter open={showNewsletterPopup} close={() => setShowNewsletterPopup(false)} />
    </>
  )
}
